.AboutUsHeader{
    text-align: center;
    justify-content: center;
    align-items: center;
    color: rgb(100, 100, 100);
    width: 270px;
    height: 50px;
margin-left: 40%;
font-family: Helvetica, sans-serif;
    background: rgba(255,255,255,0.00001);
    border-top: 1px solid rgba(255,255,255,0.5);
border-left: 1px solid rgba(255,255,255,0.5);
}

.AboutUsDescription{
    margin-top: 6rem;
    text-align: center;
    justify-content: center;
    align-items: center;

color: rgb(0, 0, 0, 0.6);
    background: rgba(255,255,255,0.00001);
    border-top: 1px solid rgba(255,255,255,0.5);
border-left: 1px solid rgba(255,255,255,0.5);
}




.CircleSectionAbout
{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    
}

.boxCircleAbout
{
position: relative;
width: 360px;
height: 200px;
display: flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
}


.circleCircleAbout
{
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 -20px;
 /*   border: 40px solid #fff;   */
    border-radius: 50%;
    box-sizing: border-box;
}


.dotCircleAbout
{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    color: #0f0;
    border-radius: 50%;
 
    animation: animateDotAbout 12s linear infinite;
    animation-delay: calc(0.3s * var(--i));
    text-shadow: 0 0 5px #0f0,
    0 0 15px #0f0,
    0 0 25px #0f0,
    0 0 50px #0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}


.BofCircleAbout
{
 font-size: 1.5rem;
 font-family: consolas;
 font-weight: normal;
 box-sizing: border-box;
 animation: fixTextAbout 12s linear infinite;
 animation-delay: calc(0.3s * var(--i));
}

@keyframes animateDotAbout
{
    0%
    {
        transform-origin: 100px 50%;
        transform: translate(-50% , -50%) rotate(0deg);
        filter: hue-rotate(0deg)
    }
    50%
    {
        transform-origin: 100px 50%;
        transform: translate(-50% , -50%) rotate(360deg);
    }
    50.0000001%
    {
        transform-origin: -60px 50%;
        transform: translate(-50% , -50%) rotate(360deg);
    }

    100%
    {
        transform-origin: -60px 50%;
        transform: translate(-50% , -50%) rotate(0deg);
        filter: hue-rotate(360deg)
    }
}





@keyframes fixTextAbout 
{
    0%
    {
        transform: rotate(0deg);
        filter: hue-rotate(0deg)
    }
    50%
    {
        transform: rotate(-360deg);
    }
    50.0000001%
    {
        transform: rotate(-360deg);
    }

    100%
    {
        transform: rotate(0deg);
        filter: hue-rotate(360deg)
    }
}