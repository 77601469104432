.containerDeviceInfo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    flex-wrap: wrap;
    z-index: 1;
    margin-left: 7rem;
}



.containerDeviceInfo .card
{
position: relative;
width: 400px;
height: 400px;
box-shadow: 20px 20px 50px rgba(0,0,0,0.23);
border-radius: 15px;
background: rgba(255,255,255,0.00001);
overflow: hidden;
display: flex;
justify-content: center;
align-items: center;
border-top: 1px solid rgba(255,255,255,0.5);
border-left: 1px solid rgba(255,255,255,0.5);
margin-top: 5rem;
margin-left: 7rem;

}



.containerDeviceInfo .card .content
{
    padding:20px;
    text-align: center;
    transition: 0.5s;
}


.containerDeviceInfo .card .content h2
{
    position: absolute;
    top: -1px;
    right: 1px;
    left: 1px;
    font-size: 2rem;
    color: rgba(192, 36, 62, 0.9);
    pointer-events: none;
}


.containerDeviceInfo .card .content a 
{
position: relative;
display: inline-block;
padding: 8px 20px;
margin-top: 15px;
background: rgba(0,0,0,0.3);
color: white;
border-radius: 20px;
text-decoration: none;
font-weight: 500;

}






.containerDeviceInfo .card .content divGreen 
{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow:
      inset 0 0 50px #01D964,
      inset 20px 0 80px #01D964,
      inset -20px 0 80px #01D964,
      inset 20px 0 300px #01D964,
      inset -20px 0 300px #01D964,
      0 0 50px #01D964,
      -10px 0 80px #01D964,
      10px 0 80px #01D964;
  }