*{
  margin: 0;
  padding: 0;

}



/* r*padding 0 kaldırıldı çünkü circle da var */ 
.wholeWebSiteBackGroundColor{
  background-image: url('./logo-and-photo/pattern-of-background.png');
  background-repeat: repeat;


}


.hepsi{                        
display: flex;

}


.firstBoxofCharts{
  width: 650px;
  height: 650px;
}

.chart-wrapper {
  margin-top: 4rem;
  float: left;
  align-content: center;
  margin-left: 10rem;
  margin-bottom: 4rem;
  background-image: url("./logo-and-photo/pattern-of-background.png");
  background-repeat: repeat;
  background-color: rgb(255, 255, 255);  
  -webkit-box-shadow: 1px 1px 3px 1px #000000; 
box-shadow: 1px 1px 3px 1px #000000;
}

.chart-line{
  width: 650px;
  height: 650px;
}

.watsonHeader{
  align-content: center;
  text-align: center;
}


.btns-wrapper {
  display: flex;
  margin-top: 80px;
  justify-content: center;
  position: relative;
}

input {
  margin-left: 20px;
  width: 250px;
}




.chart-pie{
  max-width: 250px;
  max-height: 250px;
  padding: 10px;
  background-image: url("./logo-and-photo/pattern-of-background.png");
  background-repeat: repeat;
  background-color: rgb(255, 255, 255);    
  -webkit-box-shadow: 1px 1px 3px 1px #000000; 
box-shadow: 1px 1px 3px 1px #000000;
}


.bar{
  max-height: 400px;
  margin-left: 50px;
  background-image: url("./logo-and-photo/pattern-of-background.png");
  background-repeat: repeat;
  background-color: rgb(255, 255, 255);   
  -webkit-box-shadow: 1px 1px 3px 1px #000000; 
box-shadow: 1px 1px 3px 1px #000000;
padding: 20px;
}

.protocol-chart-wrapper {
  margin-top: 5rem;
  float: left;
  align-content: center;
  margin-left: 5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
}




.divk {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow:
    inset 0 0 50px #fff,
    inset 20px 0 80px #f0f,
    inset -20px 0 80px #0ff,
    inset 20px 0 300px #f0f,
    inset -20px 0 300px #0ff,
    0 0 50px #fff,
    -10px 0 80px #f0f,
    10px 0 80px #0ff;
}



.divRed {
  margin-right: 7px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow:
    inset 0 0 50px #FF0000,
    inset 20px 0 80px #FF0000,
    inset -20px 0 80px #FF0000,
    inset 20px 0 300px #FF0000,
    inset -20px 0 300px #FF0000,
    0 0 50px #FF0000,
    -10px 0 80px #FF0000,
    10px 0 80px #FF0000;
}


.divGreen {
  margin-right: 7px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow:
    inset 0 0 50px #01D964,
    inset 20px 0 80px #01D964,
    inset -20px 0 80px #01D964,
    inset 20px 0 300px #01D964,
    inset -20px 0 300px #01D964,
    0 0 50px #01D964,
    -10px 0 80px #01D964,
    10px 0 80px #01D964;
}

.divYellow {
  margin-right: 7px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow:
    inset 0 0 50px #f1f508,
    inset 20px 0 80px #f1f508,
    inset -20px 0 80px #f1f508,
    inset 20px 0 300px #f1f508,
    inset -20px 0 300px #f1f508,
    0 0 50px #f1f508,
    -10px 0 80px #f1f508,
    10px 0 80px #f1f508;
}



.dotRed {
  height: 25px;
  width: 25px;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
}


.dotGreen {
  height: 25px;
  width: 25px;
  background-color: #00ff00;
  border-radius: 50%;
  display: inline-block;
}


@keyframes dotYellow {
  from {background-color: rgb(255,255,0);}
  to {background-color: rgb(153,153,0);}
}


.dotYellow {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;

  animation: dotYellow 1s alternate infinite;
}


.dotYellowPulse {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: #ffd500;
  animation: pulse 1500ms infinite;
}

@keyframes pulse{
  0% {
    box-shadow: #ffd500 0 0 0 0;
  }
  75% {
    box-shadow: #ffd50000 0 0 0 16px;
  }
}







.progressbar{
  position: relative;
  overflow: hidden;
  width: 350px;
  height: 35px;
  border-radius: 10px;
  background-color: #eee;
}


.progressPercent{
  font-weight: 600;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-shadow: -1px 0 #555 0 1px #555 1px 0 #555 0 -1px #555;
}
























.chart-wrapperOneBigChart {
  margin-top: 1rem;
  align-content: center;
  align-items: center;
  align-self: center;
  background-repeat: repeat;
  -webkit-box-shadow: 1px 1px 3px 1px #000000; 
  box-shadow: 1px 1px 3px 1px #000000;
  aspect-ratio: unset;
}



.chart-wrapper, canvas {
margin: auto;
aspect-ratio: unset;
}




.chart-wrapper-mqqt-link > canvas {
  margin: auto;
  max-width: 3250px;
  height:"70vh";
  width:"70vh";
}




.chart-wrapper-mqqt-link {
  margin-top: 4rem;
  margin-left: 7rem;
  float: left;
  align-content: center;
  background-image: url("./logo-and-photo/pattern-of-background.png");
  background-repeat: repeat;
  background-color: rgb(255, 255, 255);  
  -webkit-box-shadow: 1px 1px 3px 1px #000000; 
box-shadow: 1px 1px 3px 1px #000000;
}


.watsonHeader{
  align-content: center;
  text-align: center;
  padding-top: 40px;
}


.chart-line-mqqt-link{
  height:"70vh";
  width:"70vh";
  margin-bottom: 1.3rem;
}




.chart-line-traceroute{
  height:"300px";
  width:"300px";
  margin-bottom: 1.3rem;
}