
.sectionCircle
{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;
}

.boxCircle
{
position: relative;
width: 72px;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
}


.circleCircle
{
    position: relative;
    width: 40px;
    height: 40px;
    margin: 0 -4px;
    border: 8px solid #140c1f;
    border-radius: 50%;
    box-sizing: border-box;
}


.dotCircle
{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    color: #0f0;
    border-radius: 50%;
 
    animation: animateDot 12s linear infinite;
    animation-delay: calc(0.7s * var(--i));
    text-shadow: 0 0 5px #0f0,
    0 0 15px #0f0,
    0 0 25px #0f0,
    0 0 50px #0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}


.BofCircle
{
 font-size: 1rem;
 font-family: consolas;
 font-weight: normal;
 box-sizing: border-box;
 animation: fixText 12s linear infinite;
 animation-delay: calc(0.3s * var(--i));
}

@keyframes animateDot 
{
    0%
    {
        transform-origin: 20px 50%;
        transform: translate(-50% , -50%) rotate(0deg);
        filter: hue-rotate(0deg)
    }
    50%
    {
        transform-origin: 20px 50%;
        transform: translate(-50% , -50%) rotate(360deg);
    }
    50.0000001%
    {
        transform-origin: -12px 50%;
        transform: translate(-50% , -50%) rotate(360deg);
    }

    100%
    {
        transform-origin: -12px 50%;
        transform: translate(-50% , -50%) rotate(0deg);
        filter: hue-rotate(360deg)
    }
}





@keyframes fixText 
{
    0%
    {
        transform: rotate(0deg);
        filter: hue-rotate(0deg)
    }
    50%
    {
        transform: rotate(-360deg);
    }
    50.0000001%
    {
        transform: rotate(-360deg);
    }

    100%
    {
        transform: rotate(0deg);
        filter: hue-rotate(360deg)
    }
}