
  .chart-wrapperOneChart {

    margin-top: 4rem;
    margin-left: 8rem;
    float: left;
    align-content: center;
    margin-bottom: 4rem;
    background-image: url("../../logo-and-photo/pattern-of-background.png");
    background-repeat: repeat;
    background-color: rgb(255, 255, 255);  
    -webkit-box-shadow: 1px 1px 3px 1px #000000; 
  box-shadow: 1px 1px 3px 1px #000000;
  }


  .chart-wrapperPopUp {
    float: left;
    align-content: center;
    background-color: #202646;  
    -webkit-box-shadow: 1px 1px 3px 1px #000000; 
  box-shadow: 1px 1px 3px 1px #000000;
  }

  .chart-wrapperPopUp-Radar {
    margin-top: 2rem;
    float: right;
    align-content: center;
    background-color: rgb(22, 26 ,30, 0.0.1);  
    -webkit-box-shadow: 1px 1px 1px 1px #000000; 
  box-shadow: 1px 1px 1px 1px #000000;
  }


  .chart-popperOneChart {

    float: left;
    align-content: center;
    background-image: url("../../logo-and-photo/pattern-of-background.png");
    background-repeat: repeat;
    background-color: rgb(255, 255, 255);  
    -webkit-box-shadow: 1px 1px 3px 1px #000000; 
  box-shadow: 1px 1px 3px 1px #000000;
  }


  .chart-wrapper-timeSeries {

    margin-left: 1rem;
    float: left;
    align-content: center;
    margin-bottom: 4rem;
    background-image: url("../../logo-and-photo/pattern-of-background.png");
    background-repeat: repeat;
    background-color: rgb(255, 255, 255);  
    -webkit-box-shadow: 1px 1px 3px 1px #000000; 
  box-shadow: 1px 1px 3px 1px #000000;
  }

  .chart-wrapper-timeSeries-first {

    margin-left: 8.5rem;
    float: left;
    align-content: center;
    margin-bottom: 4rem;
    background-image: url("../../logo-and-photo/pattern-of-background.png");
    background-repeat: repeat;
    background-color: rgb(255, 255, 255);  
    -webkit-box-shadow: 1px 1px 3px 1px #000000; 
  box-shadow: 1px 1px 3px 1px #000000;
  }


  .watsonHeaderOneChart{
    margin-right: 3rem;
    align-content: center;
    text-align: center;
  }
  
  
  .chart-lineOneChart{
    width: 1250px;
    height: 1250px;
  }

  .chart-line-timeSeries{
    width: 400px;                
  }
