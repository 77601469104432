.sidebar{
    height: calc(100vh - 4.12rem);      
    position: sticky;
    overflow: auto;
    background: rgba(228, 239, 255,0.75);
box-shadow: 20px 20px 50px rgba(0,0,0,0.07);
}

.sidebarWrapper{
    padding:7px;
    color: rgb(0, 0, 0,0.41);
}

.sidebarMenu{
    margin-bottom: 10px;
}


.sidebarTitle{
    font-size: 13px;
    color: rgb(187,186,186);
}


.sidebarList{
    list-style: none;
    padding: 5px;

}


.sidebarListItem{
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    text-decoration: none;
}
.sidebarListItem.current{
    color:red;
}

.sidebarListItem.active,
.sidebarListItem:hover {
background-color: rgb(168, 239, 255,0.34);
}


.sidebarListItem.active,
.sidebarListItem:focus {
background-color: rgba(255, 1, 1, 0.849);
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}


.link {
    text-decoration: none;
    color: inherit;
}